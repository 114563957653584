
import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import Item from "@/components/items/Item.vue";
import { SteamItemEntity } from "@/entities/steam-item.entity";
import { UserItemEntity } from "@/entities/user-item.entity";
import Grid from "@/components/base/Grid.vue";
import BaseList from "@/components/base/BaseList.vue";
import BottomSheet from "@/components/base/BottomSheet.vue";
import GradientBtn from "@/components/base/GradientBtn.vue";

@Component({
  components: {
    GradientBtn,
    Item,
    BottomSheet,
    Grid,
    BaseList,
  },
})
export default class ItemList extends Vue {
  @Prop() scroll!: boolean | null;
  @Prop() scrollId!: string | null;
  @Prop() colsMap!: number[][] | null;
  @Prop({ default: 0 }) minPrice!: number;
  @Prop({ default: 999999 }) maxPrice!: number;
  @Prop() items!: Array<SteamItemEntity | UserItemEntity> | null;
  @Prop({ default: 400 }) scrollHeight!: number;
  @Prop({ default: undefined }) basic!: any | undefined;
  @Prop({ default: true }) selectable!: boolean;
  @Prop({ default: false }) filterBtn!: boolean;
  @Prop({ default: () => [] }) selectedItems!: Array<
    SteamItemEntity | UserItemEntity
  >;
  @PropSync("priceRange") _priceRange!: [number, number] | null;
  @PropSync("sortBy", { default: "-price" }) _sortBy!: string;
  @PropSync("searchText", { default: "" }) _searchText!: string;
  private showFilter = false;

  get selectedItemsMap() {
    return new Map<string, boolean>(
      this.selectedItems
        .map((item) => (item ? [item._id, true] : undefined))
        .filter((v) => v !== undefined) as Array<[string, boolean]>
    );
  }

  get _colsMap() {
    return (
      this.colsMap || [
        [900, 6],
        [750, 5],
        [500, 4],
        [451, 2],
        [0, 2],
      ]
    );
  }
}
